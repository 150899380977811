import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import ItemPreview from '../components/itemPreview/item-preview'

class CityTemplate extends React.Component {
  render() {
    const city = get(this.props, 'data.contentfulCity')
    const neighborhoods = get(this, 'props.data.allContentfulNeighborhood.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo
          title={city.name}
          description={''}
          image={''}
        />
        
          <>
            <div>CITY</div>
            <h1>{ city.name }</h1>
          </>

          <hr/>
          <h2>NEIGHNORHOODS</h2>
          <ItemPreview items={neighborhoods} slugName="neighborhood" />

      </Layout>
    )
  }
}

export default CityTemplate

export const pageQuery = graphql`
  query CityBySlug(
    $slug: String!
    $cityName: String!
  ) {
    contentfulCity(slug: { eq: $slug }) {
        name
        slug
    }
    allContentfulNeighborhood(filter: {cities: {elemMatch: {name: {eq: $cityName }}}}) {
      nodes {
        name
        slug
      }
    }
  }
`
